<template>
  <div class="case-creation__step case-creation__Case-information">
    <el-row>
      <el-col :span="24">
        <div class="case-creation__card">
          <div class="case-creation__heading edit-view">
            <div class="title-with-icon">
              <span class="icon">
                <img
                  src="@/assets/images/icons/icon-case-info.svg"
                  alt="icon"
                  class="img-fluid"
                />
              </span>
              <h3 class="title">Case Information</h3>
            </div>
          </div>
          <div class="case-creation__body pb-0">
            <el-row :gutter="15">
              <el-col :xl="4" :lg="4" :md="3" :sm="6" :xs="6">
                <div
                  class="input-value view-cases"
                  v-if="getCaseInformation && getCaseInformation.case_types"
                >
                  <h4 class="name">Case Type</h4>
                  <ul
                    v-for="(caseType, index) of getCaseInformation.case_types"
                    :key="index"
                  >
                    <li>{{ caseTypeMap[caseType] }}</li>
                  </ul>
                </div>
              </el-col>
              <el-col :xl="2" :lg="4" :md="3" :sm="6" :xs="6">
                <div class="input-value view-cases">
                  <h4 class="name">Order Date</h4>
                  <p
                    class="value"
                    v-if="
                      getCaseInformation && getCaseInformation.ordering_date
                    "
                  >
                    {{ formatDate(getCaseInformation.ordering_date) || "--" }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="4" :lg="4" :md="3" :sm="6" :xs="6">
                <div class="input-value view-cases">
                  <h4 class="name">Ordering Facility</h4>
                  <p
                    class="value"
                    v-if="getCaseInformation && getCaseInformation.hospital"
                  >
                    {{ getCaseInformation.hospital.name }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="4" :lg="4" :md="3" :sm="6" :xs="6">
                <div class="input-value view-cases">
                  <h4 class="name">Ordering Facility Branch</h4>
                  <p
                    class="value"
                    v-if="
                      getCaseInformation && getCaseInformation.hospital_branch
                    "
                  >
                    {{ getCaseInformation.hospital_branch.display_name }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="3" :sm="6" :xs="6">
                <div class="input-value view-cases">
                  <h4 class="name">Ordering Physician</h4>
                  <p
                    class="value"
                    v-if="
                      getCaseInformation &&
                      getCaseInformation.ordering_physician
                    "
                  >
                    {{ getCaseInformation.ordering_physician.name }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>

              <el-col :xl="3" :lg="4" :md="3" :sm="6" :xs="6">
                <div class="input-value view-cases">
                  <h4 class="name">Referring Physician</h4>
                  <p
                    class="value"
                    v-if="
                      getCaseInformation &&
                      getCaseInformation.referring_physician
                    "
                  >
                    {{ getCaseInformation.referring_physician.name }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="3" :sm="6" :xs="6">
                <div class="input-value view-cases">
                  <h4 class="name">Bill To</h4>
                  <p
                    class="value"
                    v-if="getCaseInformation && getCaseInformation.billing_type"
                  >
                    {{ getCaseInformation.billing_type.split("_").join(" ") }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>

              <el-col :xl="4" :lg="4" :md="3" :sm="6" :xs="6">
                <div class="input-value view-cases">
                  <h4 class="name">Order ID</h4>
                  <p
                    class="value"
                    v-if="getCaseInformation && getCaseInformation.order_id"
                  >
                    {{ getCaseInformation.order_id }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="13" :lg="13" :md="3" :sm="6" :xs="6">
                <div class="input-value view-cases icd-codes">
                  <div
                    class="indicatives"
                    v-if="getCaseInformation && getCaseInformation.icd_10_codes"
                  >
                    <h4 class="name">ICD-10 (Indicative)</h4>
                    <ul>
                      <li
                        class="value"
                        v-for="(
                          icd_10_code, index
                        ) in getCaseInformation.icd_10_codes"
                        :key="index"
                      >
                        {{ icd_10_code }}
                      </li>
                    </ul>
                  </div>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="3" :sm="6" :xs="6">
                <div class="input-value view-cases">
                  <h4 class="name">Collection Date</h4>
                  <p
                    class="value"
                    v-if="
                      getCaseInformation && getCaseInformation.collection_date
                    "
                  >
                    {{
                      momentWithTimezoneTime(
                        getCaseInformation.collection_date,
                        "MM-DD-yyyy hh:mm A"
                      )
                    }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>

              <el-col :xl="4" :lg="4" :md="3" :sm="6" :xs="6">
                <div class="input-value view-cases">
                  <h4 class="name">Temperature</h4>
                  <p
                    class="value"
                    v-if="getCaseInformation && getCaseInformation.temperature"
                  >
                    {{ getCaseInformation.temperature }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="6" :lg="8" :md="12">
                <div class="input-value view-cases">
                  <div
                    class="speciment-type-view"
                    v-if="getCaseInformation && getCaseInformation.sample_types"
                  >
                    <div>
                      <h4 class="name">Specimen Types</h4>
                      <ul
                        class="case_type"
                        v-if="
                          getCaseInformation.sample_types &&
                          getCaseInformation.sample_types.length
                        "
                      >
                        <li
                          class="value"
                          v-for="(
                            sampleType, index
                          ) in getCaseInformation.sample_types"
                          :key="index"
                        >
                          <span>
                            {{ caseTypeMap[sampleType.case_type] }}
                          </span>
                        </li>
                      </ul>
                      <ul v-else>
                        --
                      </ul>
                    </div>
                    <div>
                      <h4 class="name">&nbsp;</h4>
                      <ul
                        class="speciment_type"
                        v-if="
                          getCaseInformation.sample_types &&
                          getCaseInformation.sample_types.length
                        "
                      >
                        <li
                          v-for="(
                            sampleType, index
                          ) in getCaseInformation.sample_types"
                          :key="index"
                        >
                          <span
                            v-if="
                              sampleType.sample_types &&
                              sampleType.sample_types.length
                            "
                          >
                            <span
                              v-for="(sample, index) in sampleType.sample_types"
                              :key="index"
                            >
                              {{ sample }}
                              <span
                                v-if="
                                  index + 1 < sampleType.sample_types.length
                                "
                                >,</span
                              >
                            </span>
                          </span>
                          <span v-else> -- </span>
                        </li>
                      </ul>
                      <ul v-else>
                        --
                      </ul>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col
                :xl="3"
                :lg="4"
                :md="8"
                :sm="12"
                :xs="12"
                v-if="
                  hadClinicalChemistry &&
                  (getCaseInformation.is_fasting == false ||
                    getCaseInformation.is_fasting == true)
                "
              >
                <div class="input-value view-cases">
                  <h4 class="name">Fasting</h4>
                  <p class="value">
                    {{ getCaseInformation.is_fasting ? "Yes" : "No" }}
                  </p>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";

export default {
  mixins: [UserPermissionsHelper],

  computed: {
    ...mapGetters("orders", ["getCaseInformation"]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    getPatientInformation() {
      if (
        this.getCaseInformation &&
        Object.keys(this.getCaseInformation).length > 0 &&
        this.getCaseInformation.patient_info
      ) {
        return this.getCaseInformation.patient_info;
      }
      return null;
    },
    isCaseCompleted() {
      if (!this.getCaseInformation) {
        return false;
      }
      return this.getCaseInformation.status === "COMPLETE";
    },
    hadClinicalChemistry() {
      if (
        this.getCaseInformation &&
        this.getCaseInformation.case_types &&
        this.getCaseInformation.case_types.indexOf("CLINICAL_CHEMISTRY") > -1
      ) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
    };
  },
  methods: {
    goToEditForm() {
      this.$router.push({
        name: "Edit-Order-Info",
        params: { order_id: this.$route.params.order_id },
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.icd-codes {
  .indicatives {
    ul {
      display: flex;
      li {
        margin: 0 6px;
        p {
          .value {
            padding: 3px 12px !important;
          }
        }
      }
    }
  }
}
</style>
